<template>
  <div
    class="welfare_main"
    v-if="freeInfoList && freeInfoList.length > 0"
  >
    <div class="welfare_box">
      <ol style="padding-left: 15px">
        <li v-for="(item, index) in freeInfoList" :key="index">
          <span v-if="item.discountType == 'register'"
            >注册送每天免费使用{{ item.todayLimitCount }}次,超出次数按<small>{{
              needIntegral
            }}</small
            >积分每次</span
          >
          <span v-if="item.discountType == 'inviteRegister'"
            >邀请{{ item.inviteCount }}人成功注册，每天免费使用{{
              item.todayLimitCount
            }}次</span
          >
        </li>
      </ol>
      <!-- <div class="welfare_share">
        <span style="margin-right: 5px">推广链接</span>
        <el-input
          v-model="shareData.shref"
          size="mini"
          :disabled="true"
          placeholder="请登录查看您的邀请码"
          style="margin-top: 5px; flex: 1"
        >
          <el-button slot="append" @click="copyWay(shareData.shref)"
            >复制</el-button
          >
        </el-input>
      </div>
      <div>
        已邀请成功
        <span style="color: red; font-size: 16px">{{
          shareData.inviteCount
        }}</span>
        人
        <span
          style="color: #fff; cursor: pointer"
          @click="toRoute('/personalCenter')"
          >查看详情</span
        >
      </div> -->
      <div class="free-tip">
        <span v-if="freeCount === null"
          >您还没有免费的使用次数</span
        >
        <span v-else
          >每日可免费使用{{ freeCount }}次，当日已使用免费
          <span style="color: red; font-size: 16px">{{ usedCount }}</span>
          次</span
        >
        <span
          class="noIntegral"
          @click="openIntegral"
          v-if="$route.path == '/QueryWant'"
          >没有积分？<i
            :class="Integral ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          ></i
        ></span>
      </div>
      <div class="jifen_tip" v-if="$route.path == '/QueryWant' && Integral">
        <p>积分Tips：</p>
        <!-- <p>①邀请获得。每成功邀请1人奖励500积分。累计成功邀请奖励叠加。</p> -->
        <!-- <p>②积分充值。</p> -->
        <p v-for="(item, index) in rechargeList" :key="index">
          <template>
            现充值{{ item.rechargeMoney }}元积分<span
              v-if="item.awardIntegral / 100 / item.rechargeMoney < 1"
              >立省<span>{{ item.awardIntegral / 100 }}</span
              >元</span
            ><span v-if="item.awardIntegral / 100 / item.rechargeMoney >= 1"
              ><span
                >买一送{{
                  $comm.NoToChinese(
                    parseInt(item.awardIntegral / 100 / item.rechargeMoney)
                  )
                }}</span
              ></span
            >
            ，可得<span>{{
              item.rechargeMoney * 100 + item.awardIntegral
            }}</span
            >积分
            <el-button
              type="text"
              size="mini"
              @click="rechargeHandle(item.rechargeMoney)"
              style="color: red; padding: 2px 4px; float: right"
            >
              ({{ item.rechargeMoney }}元)充值
            </el-button>
          </template>
        </p>
      </div>
    </div>

    <!-- 充值弹窗 -->
    <recharge ref="recharge"></recharge>
  </div>
</template>



<script>
import moment from "moment";
import {
  getInterfaceFreeInfo,
  queryFreeUseInfo,
  rechargeApi,
} from "@/request/api";
import Recharge from "@/components/public/recharge.vue";

export default {
  props: ["modelCode"],
  data() {
    return {
      freeInfoList: [],
      freeCount: null,
      usedCount: 0,
      rechargeList: [],
      Integral: false,
    };
  },
  components: {
    Recharge,
  },
  created() {
    this.$store.dispatch("getRechargeList").then((rechargeList) => {
      this.rechargeList = rechargeList;
    });
    this.getFreeInfo();
    //刷新
    this.$on("refresh", this.initFreeUseInfo);
  },
  methods: {
    getFreeInfo() {
      //获取免费信息
      getInterfaceFreeInfo({
        modelCode: this.modelCode,
      }).then((data) => {
        let freeInfoList = data.data || [];
        this.freeInfoList = freeInfoList.filter((item) => {
          return (
            item.discountType == "register" ||
            item.discountType == "inviteRegister"
          );
        });
      });
    },
    // 获取每日使用次数
    initFreeUseInfo() {
      queryFreeUseInfo({
        modelCode: this.modelCode,
      }).then((data) => {
        let freeUser = data.freeUser;
        let freeRule = data.freeRule;
        if (freeUser == null) {
          this.freeCount = null;
        } else {
          this.freeCount = freeRule.todayLimitCount;
          if (freeUser.currDate == moment().format("YYYY-MM-DD")) {
            this.usedCount = freeUser.todayUsedCount;
          } else {
            this.usedCount = 0;
          }
        }
      });
    },
    // 复制
    copyWay(val) {
      if (val) {
        this.$comm.copy(val + "\n" + this.$store.state.copySuffix);
      } else {
        this.$message.warning("请登录查看您的邀请码");
      }
    },
    rechargeHandle(fee) {
      this.$nextTick(() => {
        this.$refs.recharge.init(fee, true, true);
      });
    },

    openIntegral() {
      this.Integral = !this.Integral;
    },
  },
  computed: {
    needIntegral: function(){
        return this.$store.state.modelIntegralMap.get(this.modelCode);
    },
    shareData: function () {
      return this.$store.state.shareObj || {};
    }
  },
};
</script>


<style lang="less" scoped>
.welfare_main {
  padding: 10px 20px;
  width: 410px;

  .welfare_box {
    font-size: 12px;
    color: #000;
    padding: 20px;
    background: linear-gradient(90deg, #dabfff, #409eff);
    border-radius: 8px;

    p {
      line-height: 22px;
    }

    .welfare_share {
      display: flex;
      align-items: center;
    }

    .jifen_tip {
      p {
        line-height: 18px;
      }
    }
  }
}

.noIntegral {
  float: right;
  cursor: pointer;
  color: #eee;
}

.free-tip{
  padding-left: 14px;
  margin-top: 8px;
  line-height:1.6;
}
</style>