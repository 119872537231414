<template>
  <div class="banner" style="background:#fff;padding:20px;margin-bottom:0;">
    <warning-tip code="KEY_ANALYSIS_SEARCH"></warning-tip>
    <main-top title="蓝海词查询" spend="KEY_ANALYSIS_SEARCH"/>
    <!-- 下拉选择 -->
    <div class="input-wrapper">
      <div class="input-line">
        <div class="input-in">
          <el-row style="width:100%;">
            <el-input
                placeholder="请输入内容"
                v-model="input_data"
                clearable
                @keyup.enter.native="seekFn()"
                style="width:30%;min-width:200px;"
                size="small"
              ></el-input>

            <el-button type="primary" round @click="seekFn()" size="small" >搜索</el-button>

            <div  class="input-condition">
              <el-radio-group v-model="searchType" size="mini">
                <el-radio :label="2" border="" >指定日期</el-radio>
                <el-radio :label="1" border="" >最近7天</el-radio>
                <el-radio :label="3" border="" >最近一个月</el-radio>
              </el-radio-group>
              <span style="padding-left:40px;"></span>
              <el-date-picker
                v-model="statisDate"
                align="right"
                type="date"
                :clearable="false"
                placeholder="可以选择本月日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                @change="seekFn()"
              ></el-date-picker>
            </div>
          </el-row>
          <el-row style="width:100%;">
              <!-- 查询结果 -->
              <div class="search-tool">
                <div class="search-result">
                  <div>
                    <span class="search-arrow">
                        查询结果
                    </span>
                    <span style="margin-left:20px;color:#333;font-size:14px;">
                      {{recommendWord == 1 ? "新手词" : "全部"}}
                    </span>
                  </div>
                  <div class="search-control">
                    <span class="search-time">统计日期：{{ statisDate }}</span>
                    <span style="float:right;">
                      <el-button  size="small"   @click="seekFn(null, 1)" >
                        新手一键淘词
                        <el-tooltip effect="dark" placement="top" content="系统搜索较多且商品较少,偏冷门词">
                            <i class="el-icon-question"  style="color:#409eff;margin-left:5px;cursor:pointer;"></i>
                        </el-tooltip>
                      </el-button>
                      <el-button type="primary" size="small"  @click="downloadExcel">一键导出</el-button>
                    </span>

                  </div>
                </div>
              </div>
              
          </el-row>
        </div>
      </div>
      

      <!-- 优惠 -->
      <!-- <discount-tool model-code="KEY_ANALYSIS_SEARCH" ref="discountTool" ></discount-tool> -->
      
    </div>



    <!-- 复选框下面的table -->
    <el-table
      height="500"
      size="mini"
      :data="
        tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)
      "
      highlight-current-row
      style="width: 100%"
      v-loading="loading"
      @sort-change="clickSort"
    >
      <el-table-column
        v-for="(item, index) in checkbox_group"
        :key="index"
        :label="item.name"
        :prop="item.value"
        align="center"
        :sortable="index == 0 ? false : 'custom'"
        :min-width="item.minWidth ? item.minWidth : '60px'"
        :render-header="(temp1, temp2) => renderHeader(temp1, temp2, item.name)"
      >
        <template slot-scope="scope">
          <div v-if="item.name == '搜索词'">
            <el-link  @click="seekFn(scope.row[item.value])">
              <!-- <span v-html="formatKeyword(scope.row[item.value], scope.row.brandWord)"></span> -->
              {{scope.row[item.value]}}
            </el-link>
          </div>
          <div v-else-if="item.name == '支付转化率'">
            {{accMul(scope.row[item.value], 100) + "%" }}
          </div>
          <div v-else-if="item.name == '类型' "> 
            <el-tag v-if="scope.row.bussType == 1">7天</el-tag>
            <el-tag v-if="scope.row.bussType == 2">指定日期</el-tag>
            <el-tag v-if="scope.row.bussType == 3">30天</el-tag>
          </div>
          <div v-else >
            {{scope.row[item.value]}}
          </div>
          <el-tag type="danger" size="mini"  effect="dark" v-if="item.name == '搜索词' &&  scope.row.wordFeature == 1 ">人造词</el-tag>
          <el-tag type="warning" size="mini"  effect="dark" v-if="item.name == '搜索词' &&  scope.row.brandFeature == 1 " :title="scope.row.brandWord">品牌词</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="图表" align="center" width="80" >
        <template slot-scope="scope" class="box-container">
          <span class="look_btn" @click="view_details(scope.$index, scope.row)">查看</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div style="height:72px;">
    <el-pagination
      style="float:right; margin: 20px 50px 20px 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 50, 100]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    ></el-pagination>
    </div>

      <el-dialog
        :title="dialogTableVisible_title"
        :visible.sync="dialogTableVisible"
        width="80%"
      >
        <template slot="title">
          <div>
            <span style="padding:0px 10px;font-size:18px;">{{dialogTableVisible_title}}</span>
            <small>统计时间：{{statisTime}}</small>
          </div>
        </template>
        <!-- 头部选择按钮 -->
        <div class="pane">
          <div
            v-for="(item, index) in choice_box_data"
            :key="index"
            @click="pitch_on_fn(item.name, index)"
            :class="
              Chick_index == index ? 'border-color pane-in' : 'pane-in'
            "
          >
            <p>{{ item.name }}</p>
            <!-- <h3 class="center">{{ item.numericalValue }}</h3> -->
            <!-- <p class="bottom">
              <span>较前1日</span>
              <span>
                {{ item.SortOfNumerical
                }}
                <i v-if="item.sort" class="el-icon-upload2"></i>
                <i v-else class="el-icon-download"></i>
              </span>
            </p> -->
          </div>
          <!-- 查看详情的折线图 -->
          <el-col :span="24" class="e-charts">
            <el-card>
              <div style="width: 100%; height: 320px" ref="detailChart"></div>
            </el-card>
          </el-col>

          <!-- 折线图下的table -->
          <el-table :data="popupTabledata" border style="width: 100%;" height="380">
            <el-table-column prop="date" label="日期"></el-table-column>
            <el-table-column prop="searchPopularity" label="搜索人数"></el-table-column>
            <el-table-column prop="searchHeat" label="搜索热度"></el-table-column>
            <el-table-column prop="clickPopularity" label="点击人气"></el-table-column>
            <el-table-column prop="clickHeat" label="点击热度"></el-table-column>
            <el-table-column prop="clickThroughRate" label="点击率">
              <template slot-scope="scope">
                  {{accMul(scope.row.clickThroughRate, 100) + "%" }}
              </template>
            </el-table-column>
            <el-table-column prop="tradingIndex" label="支付金额"></el-table-column>
            <el-table-column prop="paymentConversionRate" label="支付转化率">
              <template slot-scope="scope">
                  {{accMul(scope.row.paymentConversionRate, 100) + "%" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      
      <div class="search-explain">
          <span style="padding-right:20px;">蓝海值解释:	</span>
          <p>	
            根据搜索访客价值和商品价值共同得出,此处蓝海值越大代表关键词横向比对更为优质，对搜索更有参考价值.				
          </p>
          <p>
            本网站的蓝海值算法仅供参考, 商家朋友们可以根据自己的算法导出数据后再做二次加工.						
          </p>
      </div>
       <div class="search-explain">
          <span style="padding-right:20px;">深海机会值解释：	</span>
          <p>
            适用范围是产品少且竞争度低的关键词，数值越大表示产品越容易被搜索到；推荐使用时选择数值大于100的关键词，且剔除品牌词和人造词，对新店新品初期私域补单有较大帮助.										
          </p>
      </div>

    <SyncLoading v-if="$store.state.fishFlag"></SyncLoading>
  </div>
  
</template>


<script>

import echarts from "echarts";
import {
  getSearchDefaultList,
  getSearchList,
  getSearchDetail,
  createTraceId,
} from "@/request/api"; //这里是引入请求

import moment from "moment";
import DiscountTool from '../public/DiscountTool.vue';

const checkbox_group = [
  { value: "searchKey", name: "搜索词" },
  { value: "searchPopularity", name: "搜索人数" },
  // { value: "searchHeat", name: "搜索热度" },
  { value: "clickThroughRate", name: "点击率" },
  // { value: "clickPopularity", name: "点击人气" },
  // { value: "clickHeat", name: "点击热度" },
  { value: "tradingIndex", name: "支付金额" },
  { value: "paymentConversionRate", name: "支付转化率" },
  { value: "numOnlineProducts", name: "在线商品数" },
  { value: "shoppingClickPercentage", name: "商城点击占比" , minWidth: "80px"},
  { value: "throughTrainRefPrice", name: "直通车参考" },
  { value: "unitPricePerCustomer", name: "客单价" },
  //{ value: "searchValue", name: "搜索价值" },
  //{ value: "goodsOnlineAvgValue", name: "商品价值" },
  { value: "blueOceanValue", name: "蓝海值" },
  { value: "oceanDeepValue", name: "深海机会值" },
  // { value: "bussDate", name: "日期" },
  { value: "bussType", name: "类型" },
];

export default {
  data() {
    return {
      loading: false,
      searchType:1,
      recommendWord: 0,
      showPopover: false, //是否打开筛选框
      filterBoxFrom: {
        searchKey: "", //名字
        bussType: 1,
        bussDate: "",
        minSearchPopularity: 0, //最小搜索人数值
        maxSearchPopularity: 999999, //最大搜索人数值
        minTradingIndex: 0, //最小支付金额
        maxTradingIndex: 999999, //最大支付金额
        minPaymentConversionRate: 0, //最小支付转化率
        maxPaymentConversionRate: 999999, //最大支付转化率
        minThroughTrainRefPrice: 0, //最小直通车参考
        maxThroughTrainRefPrice: 999999, //最大直通车参考
        minNumOnlineProducts: 0, //最小在线商品数
        maxNumOnlineProducts: 999999, //最大在线商品数
        minUnitPricePerCustomer: 0, //最小客单价
        maxUnitPricePerCustomer: 999999, //最大客单价
        minSearchValue: 0, //最小搜索价值
        maxSearchValue: 999999, //最大搜索价值
        minGoodsOnlineAvgValue: 0, //最小商品价值
        maxGoodsOnlineAvgValue: 999999, //最大商品价值
        minBlueOceanValue: 0, //最小蓝海值
        maxBlueOceanValue: 999999, //最大蓝海值
      },
      pickerOptions: {
        disabledDate(time) {
          let curDate = moment(time);
          let minDate = moment().subtract(30, "days");
          let maxDate = moment().subtract(1, "days");
          return curDate.isBefore(minDate) || curDate.isAfter(maxDate);
        }
      },
      alterColor: true,
      monthJudge: true,
      indexTransformation: "", //需要转换的数字
      popupTabledata: [], //弹窗table数据
      echartsData: {
        //echarts的数据面板
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: []
        },
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [
          {
            name: "",
            type: "line",
            markLine: {
              lineStyle: {
                normal: {
                  type: "dashed"
                }
              }
            },
            data: []
          }
        ]
      },
      choice_box_data: [
        {name:"搜索人数"},
        {name:"搜索热度"},
        {name:"点击人气"},
        {name:"点击热度"},
        {name:"点击率"},
        {name:"支付金额"},
        {name:"支付转化率"},
      ],
      choice_static_time:'',
      Chick_index: 0, //默认显示搜索人数
      marketSurveyEntityMap: "", //查询详情的订单列表数据
      dialogTableVisible: false, // 查看单项详情时的弹框
      dialogTableVisible_title: "", //查看单项详情时的标题
      currentIndex: "",
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      show2: false, // 新增热词输入框显示，默认不显示
      hot_word: "", //需要配置的热词
      dialogFormVisible: false, //热词配置弹出框显示，默认不显示
      input_data: "", //搜索框内容
      tableData: [],
      Checked_content: [], //被选中的选项value数组
      checkbox_group, //渲染的表头
      max: 5, //复选框最多可选数
      tv: localStorage.getItem("v"), //时间版本
      //模拟热词相关的显示内容
      hot_word_list: [],
      dataType: "2",
      statisDate: "",
      statisTime: "",
      av: localStorage.getItem("av") //账号版本
    };
  },
  components: {
    DiscountTool
  },
  created() {

    this.statisDate = moment().subtract(1,"days").format("YYYY-MM-DD");
    this.statisTime = moment().subtract(1,"days").format("YYYY-MM-DD HH:mm:ss");
    this.input_data = "";
    this.loading = true;
    getSearchDefaultList({
        searchKey: this.input_data,
        dataType: this.dataType,
        useId: localStorage.getItem("sycmid")
      }).then(( data ) => {
        this.loading = false;
        if (data.data != null) {
          this.tableData = data.data;
        } else {
          //否则提示获取数据失败，需要用户刷新页面
          this.$store.commit("alterFishFlag", false);
          this.$message({
            showClose: true,
            message: data.msg,
            type: "error"
          });
        }
    });
  
  },
  methods: {
    //关闭筛选框表单
    closeFrom() {
      this.showPopover = false;
    },
    //重制表单
    remakeFrom() {
      this.filterBoxFrom = {
        searchKey: this.input_data, //名字
        bussType: this.alterColor ? 2 : this.monthJudge ? 3 : 1,
        bussDate: this.statisDate,
        minSearchPopularity: 0, //最小搜索人数值
        maxSearchPopularity: 999999, //最大搜索人数值
        minTradingIndex: 0, //最小支付金额
        maxTradingIndex: 999999, //最大支付金额
        minPaymentConversionRate: 0, //最小支付转化率
        maxPaymentConversionRate: 999999, //最大支付转化率
        minThroughTrainRefPrice: 0, //最小直通车参考
        maxThroughTrainRefPrice: 999999, //最大直通车参考
        minNumOnlineProducts: 0, //最小在线商品数
        maxNumOnlineProducts: 999999, //最大在线商品数
        minUnitPricePerCustomer: 0, //最小客单价
        maxUnitPricePerCustomer: 999999, //最大客单价
        minSearchValue: 0, //最小搜索价值
        maxSearchValue: 999999, //最大搜索价值
        minGoodsOnlineAvgValue: 0, //最小商品价值
        maxGoodsOnlineAvgValue: 999999, //最大商品价值
        minBlueOceanValue: 0, //最小蓝海值
        maxBlueOceanValue: 999999 //最大蓝海值
      };
    },
    //表格表头的信息
    // h即为cerateElement的简写，具体可看vue官方文档
    renderHeader(h, { column }, name) {
      var explain = "";
      let specStyle = "";
      
      if (name == "蓝海值") {
        //explain = "蓝海值越大说明该产品需求非常高的情况下，卖该产品的商家相对较少，因此蓝海值越大则代表该产品越有参考性";
        explain = "蓝海值根据商品价值和搜索访客价值叠加得出 蓝海值越大表示该词更具有潜力";
          specStyle = "color:red;font-weight:bold;";
      } else if (name == "搜索价值") {
        explain = "平均每个搜索人数的成交金额＝关键词价值＝关键词支付金额/关键词搜索人数，可以用于直通车出价上限参考";
      } else if (name == "商品价值") {
        explain = "支付金额/在线商品数，数值超高，说明支付金额高，竞争越小，可以和蓝海值互补";
      } else {
        return name;
      }

    let eleList = [
        h("span", {
          style: specStyle,
          domProps:{
            innerHTML: column.label
          }
        })
      ];
      eleList.push(h(
          "el-tooltip",
          {
            props: {
              effect: "dark",
              content: explain,
              placement: "top"
            }
          },
          [
            h("i", {
              class: "el-icon-question",
              style: "color:#409eff;margin-left:5px;cursor:pointer;"
            })
          ]
        ));
      return h("span", eleList);
    },
    //发送筛选请求
    submitFrom() {
      if (this.input_data.length == 0) {
        this.$message({
          showClose: true,
          message: "请先输入内容，再点击搜索",
          type: "error"
        });
        return;
      }


      this.$http({
        //发送请求获取生意参谋的页面数据
        url: this.$http.adornUrl(
          "/api/sycm/screenCorrelationAnalysisInfo"
        ),
        method: "get",
        params: {
          useId: localStorage.getItem("sycmid"),
          // params: this.filterBoxFrom,
          searchKey: this.input_data, //名字
          bussType: this.alterColor ? 2 : this.monthJudge ? 3 : 1,
          bussDate: this.statisDate,
          minSearchPopularity:
            this.filterBoxFrom.minSearchPopularity == ""
              ? 0
              : this.filterBoxFrom.minSearchPopularity, //最小搜索人数值
          maxSearchPopularity:
            this.filterBoxFrom.maxSearchPopularity == ""
              ? 9999999
              : this.filterBoxFrom.maxSearchPopularity, //最大搜索人数值
          minTradingIndex:
            this.filterBoxFrom.minTradingIndex == ""
              ? 0
              : this.filterBoxFrom.minTradingIndex, //最小支付金额
          maxTradingIndex:
            this.filterBoxFrom.maxTradingIndex == ""
              ? 9999999
              : this.filterBoxFrom.maxTradingIndex, //最大支付金额
          minPaymentConversionRate:
            this.filterBoxFrom.minPaymentConversionRate == ""
              ? 0
              : this.filterBoxFrom.minPaymentConversionRate, //最小支付转化率
          maxPaymentConversionRate:
            this.filterBoxFrom.maxPaymentConversionRate == ""
              ? 9999999
              : this.filterBoxFrom.maxPaymentConversionRate, //最大支付转化率
          minThroughTrainRefPrice:
            this.filterBoxFrom.minThroughTrainRefPrice == ""
              ? 0
              : this.filterBoxFrom.minThroughTrainRefPrice, //最小直通车参考
          maxThroughTrainRefPrice:
            this.filterBoxFrom.maxThroughTrainRefPrice == ""
              ? 9999999
              : this.filterBoxFrom.maxThroughTrainRefPrice, //最大直通车参考
          minNumOnlineProducts:
            this.filterBoxFrom.minNumOnlineProducts == ""
              ? 0
              : this.filterBoxFrom.minNumOnlineProducts, //最小在线商品数
          maxNumOnlineProducts:
            this.filterBoxFrom.maxNumOnlineProducts == ""
              ? 9999999
              : this.filterBoxFrom.maxNumOnlineProducts, //最大在线商品数
          minUnitPricePerCustomer:
            this.filterBoxFrom.minUnitPricePerCustomer == ""
              ? 0
              : this.filterBoxFrom.minUnitPricePerCustomer, //最小客单价
          maxUnitPricePerCustomer:
            this.filterBoxFrom.maxUnitPricePerCustomer == ""
              ? 9999999
              : this.filterBoxFrom.maxUnitPricePerCustomer, //最大客单价
          minSearchValue:
            this.filterBoxFrom.minSearchValue == ""
              ? 0
              : this.filterBoxFrom.minSearchValue, //最小搜索价值
          maxSearchValue:
            this.filterBoxFrom.maxSearchValue == ""
              ? 9999999
              : this.filterBoxFrom.maxSearchValue, //最大搜索价值
          minGoodsOnlineAvgValue:
            this.filterBoxFrom.minGoodsOnlineAvgValue == ""
              ? 0
              : this.filterBoxFrom.minGoodsOnlineAvgValue, //最小商品价值
          maxGoodsOnlineAvgValue:
            this.filterBoxFrom.maxGoodsOnlineAvgValue == ""
              ? 9999999
              : this.filterBoxFrom.maxGoodsOnlineAvgValue, //最大商品价值

          minBlueOceanValue:
            this.filterBoxFrom.minBlueOceanValue == ""
              ? 0
              : this.filterBoxFrom.minBlueOceanValue, //最小蓝海值
          maxBlueOceanValue:
            this.filterBoxFrom.maxBlueOceanValue == ""
              ? 9999999
              : this.filterBoxFrom.maxBlueOceanValue //最大蓝海值
        }
      }).then(data => {
        //console.log(data.data.correlationAnalysisEntities);
        if (data.data.correlationAnalysisEntities != null) {
          this.currentPage = 1;
          this.tableData = data.data.correlationAnalysisEntities;
          this.clickSort({
            column: { order: "ascending", label: "搜索人数" },
            prop:"searchPopularity",
            order:"ascending"
          });
        } else {
          this.tableData = [];
        }
      });
    
    },
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    // 蓝海值格式
    blueOceanValue(temp1, temp2, temp3) {
      if (
        Number(temp1.replace(/["|’|,|\“|\”|\%，]/g, "")) == 0 ||
        Number(temp2.replace(/["|’|,|\“|\”|\%，]/g, "")) == 0 ||
        Number(temp3.replace(/["|’|,|\“|\”|\%，]/g, "")) == 0
      ) {
        return "0";
      } else {
        return (
          Math.floor(
            (Number(temp1.replace(/["|’|,|\“|\”|\%，]/g, "")) /
              Number(temp2.replace(/["|’|,|\“|\”|\%，]/g, "")) /
              Number(temp3.replace(/["|’|,|\“|\”|\%，]/g, "") / 100)) *
              100
          ) + ""
        );
      }
    },
    Actuallyget() {},
    getLastMonth() {
      //获取上个月日期 格式 2020-12
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month == 0) {
        year = year - 1;
        month = 12;
      }
      month = month < 10 ? `0${month}` : month;
      return year + "-" + month + "-01";
    },
    // 获取echarts相关的数组
    getCorrelationList(parameter) {
      var temp = [];
      for (var i in this.popupTabledata) {
        var obj = this.popupTabledata[i];
        temp.push(obj[parameter]);
      }
      this.echartsData.series[0].data = temp;
    },
    //点击查看详情内的tabs标签页时
    pitch_on_fn(item, index) {
      //点击每一项时修改折线图的y轴内容和标题
      this.Chick_index = index;
      this.echartsData.series[0].name = item;
      this.echartsData.legend.data[0] = item;
      // 定义echarts
      let myChart = echarts.init(this.$refs.detailChart);
      if (item == "搜索人数") {
        this.getCorrelationList("searchPopularity");
      } else if (item == "搜索热度") {
        this.getCorrelationList("searchHeat");
      } else if (item == "点击率") {
        this.getCorrelationList("clickThroughRate");
      } else if (item == "点击人气") {
        this.getCorrelationList("clickPopularity");
      } else if (item == "点击热度") {
        this.getCorrelationList("clickHeat");
      } else if (item == "支付金额") {
        this.getCorrelationList("tradingIndex");
      } else if (item == "支付转化率") {
        this.getCorrelationList("paymentConversionRate");
      }
      // 绘制图表
      myChart.setOption(this.echartsData);
    },
    view_details(index, row) {
      
      this.$store.commit("alterFishFlag", true);
      this.$store.commit("alterFishLoadNumber", 100000); //传load时间
      this.$store.commit("alterFishLoadContent", row.searchKey); //传load关键字
      let formObj = {
          searchKey: row.searchKey,
          useId: localStorage.getItem("sycmid")
        };
      createTraceId().then((data) => {
          let traceId  = data.data;
          formObj.traceId = traceId;
          //显示获取状态
          this.$store.commit("alterTraceId", traceId); //显示跟踪状态
          getSearchDetail(formObj).then(( data ) => {
            //没有true，只有请求是否发送成功
            this.dialogTableVisible = !this.dialogTableVisible;
            this.dialogTableVisible_title = '"' + row.searchKey + '"' + "的详情";
            
            // 以下是点击查看后的table数据处理
            let marketSurveyEntityMap = data.data;
            let newArr = [];
            for (var dateKey in marketSurveyEntityMap) {
              newArr.push({
                date: dateKey,
                searchPopularity:
                  marketSurveyEntityMap[dateKey].searchPopularity,
                searchHeat:
                  marketSurveyEntityMap[dateKey].searchHeat,
                clickPopularity:
                  marketSurveyEntityMap[dateKey].clickPopularity,
                clickHeat: 
                  marketSurveyEntityMap[dateKey].clickHeat,
                clickThroughRate:
                  marketSurveyEntityMap[dateKey].clickThroughRate,
                tradingIndex:
                  marketSurveyEntityMap[dateKey].tradingIndex,
                paymentConversionRate:
                  marketSurveyEntityMap[dateKey].paymentConversionRate
              });
            }

            //颠倒顺序
            newArr.reverse();
            if(newArr.length > 10){
              //限制显示10天的数据
              newArr  =  newArr.slice(0,10);
            }

            this.popupTabledata = newArr;
            let xSeries = [];
            for(let i in this.popupTabledata){
                xSeries.push(this.popupTabledata[i].date);
            }
            this.echartsData.xAxis.data = xSeries;
            this.$nextTick(() => {
              //初始化
              this.pitch_on_fn("搜索人数", 0);
            });


            var lastkey = "";
            for (var keyname in marketSurveyEntityMap) {
              lastkey = keyname;
            }

            this.choice_static_time = lastkey;

            for (var item in this.choice_box_data) {
              if (this.choice_box_data[item].name == "搜索人数") {
                this.choice_box_data[item]["numericalValue"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].searchPopularity;
                this.choice_box_data[item]["sort"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].searchPopularityUpFlag;
                this.choice_box_data[item]["SortOfNumerical"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].searchPopularityRate;
              } else if (
                this.choice_box_data[item].name == "搜索热度"
              ) {
                this.choice_box_data[item]["numericalValue"] =
                  marketSurveyEntityMap[lastkey].searchHeat;
                this.choice_box_data[item]["sort"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].searchHeatUpFlag;
                this.choice_box_data[item]["SortOfNumerical"] =
                  marketSurveyEntityMap[lastkey].searchHeatRate;
              } else if (this.choice_box_data[item].name == "点击率") {
                this.choice_box_data[item]["numericalValue"] =
                  this.accMul(marketSurveyEntityMap[
                    lastkey
                  ].clickThroughRate, 100) + "%";
                this.choice_box_data[item]["sort"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].clickThroughRateUpFlag;
                this.choice_box_data[item]["SortOfNumerical"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].clickThroughRateRate;
              } else if (
                this.choice_box_data[item].name == "点击人气"
              ) {
                this.choice_box_data[item]["numericalValue"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].clickPopularity;
                this.choice_box_data[item]["sort"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].clickPopularityUpFlag;
                this.choice_box_data[item]["SortOfNumerical"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].clickPopularityRate;
              } else if (
                this.choice_box_data[item].name == "点击热度"
              ) {
                this.choice_box_data[item]["numericalValue"] =
                  marketSurveyEntityMap[lastkey].clickHeat;
                this.choice_box_data[item]["SortOfNumerical"] =
                  marketSurveyEntityMap[lastkey].clickHeatRate;
                this.choice_box_data[item]["sort"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].clickHeatUpFlag;
              } else if (
                this.choice_box_data[item].name == "支付金额"
              ) {
                this.choice_box_data[item]["numericalValue"] =
                  marketSurveyEntityMap[lastkey].tradingIndex;
                this.choice_box_data[item]["SortOfNumerical"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].tradingIndexRate;
                this.choice_box_data[item]["sort"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].tradingIndexUpFlag;
              } else if (
                this.choice_box_data[item].name == "支付转化率"
              ) {
                this.choice_box_data[item]["numericalValue"] =
                  this.accMul(marketSurveyEntityMap[
                    lastkey
                  ].paymentConversionRate, 100) + "%";
                this.choice_box_data[item]["SortOfNumerical"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].paymentConversionRateRate;
                this.choice_box_data[item]["sort"] =
                  marketSurveyEntityMap[
                    lastkey
                  ].paymentConversionRateUpFlag;
              }
            }
        }).finally(() => {
          this.$store.commit("alterFishFlag", false);
        });
      })


      

    },
    // 对象排序的方法
    sortObj(obj) {
      var arr = [];
      for (var i in obj) {
        arr.push([obj[i], i]);
      }
      arr.reverse();
      var len = arr.length;
      var obj = {};
      for (var i = 0; i < len; i++) {
        obj[arr[i][1]] = arr[i][0];
      }
      return obj;
    },
    clickSort(rowObj) {
      this.sort(rowObj.prop, rowObj.order);
    },
    // 附有千位分割符排序方法
    sort(temp, order) {
      if (order == "descending") {
        //降序方法
         this.tableData.sort((a, b) => {
          return parseFloat(b[temp]) - parseFloat(a[temp]);
        });
      } else {
        //升序方法
        this.tableData.sort((a, b) => {
          return parseFloat(a[temp]) - parseFloat(b[temp]);
        });
       
      }
    },
    thousandBitSeparator(num) {
      //实现数字千位分隔符
      return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    },
    // 点击搜索的方法
    seekFn(searchKey, recommendWord) {

      if(searchKey != null){
        this.input_data = searchKey;
      }

      if (this.input_data ==null || this.input_data.length == 0) {
        this.$message({
          showClose: true,
          message: "请先输入内容，再执行操作",
          type: "error"
        });
        return; 
      }
      
      this.recommendWord = recommendWord || 0;

      //防止重复点击
      if(this.$store.state.fishFlag){
        this.$message({
          showClose: true,
          message: "请勿重复点击，等待搜索结果",
          type: "error"
        });
        return; 
      }

      this.currentPage = 1;
      var searchType = this.searchType || 2;
      if (searchType == 1) {
        this.alterColor = false;
        this.monthJudge = true;
      } else if (searchType == 3) {
        this.alterColor = true;
        this.monthJudge = false;
      } else {
        this.alterColor = true;
        this.monthJudge = true;
      }

      //新方法,点击搜索发送请求
      this.$store.commit("alterFishFlag", true); //打开load
      this.$store.commit("alterFishLoadNumber", 100000); //传load时间
      this.$store.commit("alterFishLoadContent", this.input_data); //传load关键字
      let formObj = {
          searchKey: this.input_data.trim(),
          useId: localStorage.getItem("sycmid"),
          day: this.statisDate,
          dataType: searchType,
          recommendWord: this.recommendWord
        }

      createTraceId().then((data) => {
        let traceId  = data.data;
        formObj.traceId = traceId;
        //显示获取状态
        this.$store.commit("alterTraceId", traceId); //显示跟踪状态
        getSearchList(formObj).then(( data ) => {
          this.$store.commit("alterFishFlag", false);
          this.tableData = data.data;
          
        }).finally(() => {
          //更新次数
          // this.$refs.discountTool.$emit("refresh");
        });

        

      }).catch(() => {
          //获取失败
          this.$store.commit("alterFishFlag", false);
      });
    

    },
    // size-change和current-change事件来处理页码大小和当前页变动时候触发的事件。
    handleSizeChange: function(size) {
      this.pagesize = size; //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage; //点击第几页
    },
    // 表格导出的方法
    downloadExcel() {

      this.$confirm("确定下载列表文件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.excelData = this.tableData;
          this.export2Excel();
        })
        .catch(() => {});
    },
    export2Excel() {
      
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
        // 导出的表头名信息
        const tHeader = [
          "搜索词",
          "搜索人数",
          "点击率",
          "支付金额",
          "支付转化率",
          "在线商品数",
          "商城点击占比",
          "直通车参考",
          "客单价",
          //"搜索价值",
          //"商品价值",
          "蓝海值",
          "深海机会值",
          "日期",
          "类型",
        ]; 
        // 导出的表头字段名，需要导出表格字段名
        const filterVal = [
          "searchKey",
          "searchPopularity",
          "clickThroughRate",
          "tradingIndex",
          "paymentConversionRate",
          "numOnlineProducts",
          "shoppingClickPercentage",
          "throughTrainRefPrice",
          "unitPricePerCustomer",
          //"searchValue",
          //"goodsOnlineAvgValue",
          "blueOceanValue",
          "oceanDeepValue",
          "bussDate",
          "typeText",
        ];        
        
        if(this.recommendWord != 1){
          tHeader.push("属性");
          filterVal.push("proptyText");
        }
        
        const list = that.excelData;
        that.excelData.forEach(function(item){
          let typeText  = "";
          if(item.bussType == 1){
              typeText = "7天";
          }else if(item.bussType == 2){
              typeText = "指定日期";
          }else if(item.bussType == 3){
              typeText = "30天";
          }
          item.typeText = typeText;

          
          let propAry = [];
          if(item.wordFeature == 1){
              propAry.push("人造词");
          }
          if(item.brandFeature == 1){
              propAry.push("品牌词");
          }
          item.proptyText = propAry.join(",");

          
        });

        const data = that.formatJson(filterVal, list);

        let curDate = moment().format("YYYY-MM-DD");
        export_json_to_excel(tHeader, data, "搜索分析表-" + curDate); // 导出的表格名称，根据需要自己命名
      });
    },
    // 格式转换，直接复制即可
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    formatKeyword(keyword, brandWord){
      if(!keyword){
        return keyword;
      }else if(brandWord != null){
        let newVal = "";
        let startIndex  = keyword.indexOf(brandWord);
        let endIndex = startIndex + brandWord.length;
        if(startIndex != -1){
            newVal += keyword.substring(0, startIndex);
            newVal += "<span style='background-color:#e6a23c;color:white;'>" + brandWord + "</span>";
            newVal += keyword.substring(endIndex);
            return newVal;
        }
      }
      return keyword;
    },
    
  },
};
</script>
 
<style lang = "less" scoped>

.input-wrapper{
  display: flex;
}

.input-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  flex: 1;

  .input-in {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .el-input {
      margin-right: 20px;
    }
  }
}
.box-container {
  display: flex;
  align-items: center;
  span {
    margin-left: 20px;
    color: #17b3a3;
  }
}
.look_btn {
  margin-right: 12px;
  cursor: pointer;
  color: #17b3a3;
}
.pane {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .pane-in {
    width: 180px;
    /* height: 100px; */
    border: 1px solid #999;
    border-radius:6px;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
    cursor:pointer;
    .center {
      font-size: 18px;
      margin: 0;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
    }
    p {
      margin: 5px 0;
    }
    .el-icon-download {
      color: #45c2b5;
      font-weight: bold;
    }
    .el-icon-upload2 {
      color: red;
      font-weight: bold;
    }
  }
  .border-color {
    border: 2px solid #45c2b5;
  }
  .e-charts {
    margin: 10px 0;
  }
}
.alterColor1 {
  background-color: #fff;
  color: #000;
}
.alterColor2 {
  background-color: #409eff;
  color: #fff;
}
.monthJudge2 {
  background-color: #e6a23c;
  color: #fff;
}
.filterBox {
  display: flex;
  line-height: 40px;
}
/* /deep/.el-table .caret-wrapper {
  vertical-align: baseline;
} */

.input-condition{
  display:inline-block;
  margin:4px 0px;
}

.search-tool{
  padding:0px;
}

.search-result{
  padding-left:0px;
}

.search-arrow{
  position:relative;
  display:inline-block;
  border:solid 1px #409eff;
  border-right: none;  
  line-height:28px;
  font-size:14px;
  padding:0px 20px;
  background-color:#409eff;
  color:#fff;
}

.search-arrow:before{
  content:"";
  position:absolute;
  display:inline-block;
  line-height:0;
  height:0px;
  border:solid 15px #409eff;
  border-top-color:transparent;
  border-bottom-color:transparent;
  border-right-color:transparent;
  left:100%;
  top:-1px;
  margin-left: 0px;
}


.search-control{
  padding:8px;
  text-align:right;
  overflow: hidden;
}

.search-time{
  line-height:34px;
  float:left;
  font-size: 14px;
}

.search-explain{
  padding-top:0px;
  padding-bottom:20px;
  color:#333;
  line-height:1.8;
  font-size: 12px;
}


</style>